export var BASE_URL = "";
export var CURRENT_ENV = "DEV";

if (CURRENT_ENV == "DEV") {
  // BASE_URL = "https://cortevamcrcuatapi.azurewebsites.net/rest/";
  //  BASE_URL = "http://10.20.0.18:9848/rest/";
  BASE_URL = "https://api.cortevaseedresearch.in/rest/";
} else {
  BASE_URL = "https://cortevamcrcuatapi.azurewebsites.net/rest/";
}
export const LOGIN = BASE_URL + "mcrc/validateUserIDANDPassword";

export const notifications = BASE_URL + "mcrc/Login/notifications";
export const forgetPassword = BASE_URL + "mcrc/Login/forgetPassword";

//RoleMAster
export const addRoleMaster = BASE_URL + "mcrc/addRoleMaster";
export const getRoleMaster = BASE_URL + "mcrc/getRoleMaster";

//UserMaster
export const getUserMaster = BASE_URL + "mcrc/getUserMaster";
export const getManagerBasedOnCostCenter =
  BASE_URL + "mcrc/getManagerBasedOnCostCenter";
export const getFunctionalLeadBasedOnCostCenter =
  BASE_URL + "mcrc/getFunctionalLeadBasedOnCostCenter";
export const getCostCenterBasedOnFunctionalLead =
  BASE_URL + "mcrc/getCostCenterBasedOnFunctionalLead";
export const getEmployeeBasedOnManager =
  BASE_URL + "mcrc/getEmployeeBasedOnManager";
export const addUserMaster = BASE_URL + "mcrc/addUserMaster";
export const getUserSupportedMasters =
  BASE_URL + "mcrc/getUserSupportedMasters";
export const getUserTypeMaster = BASE_URL + "mcrc/getUserTypeMaster";
export const resetPassword = BASE_URL + "mcrc/resetPassword";

//MainStoreMaster
export const getMainStoreMaster = BASE_URL + "mcrc/getMainStoreMaster";
export const addMainStoreMaster = BASE_URL + "mcrc/addMainStoreMaster";

//Ui Master
export const addUnitMeasureMaster = BASE_URL + "mcrc/addUIMMaster";
export const getUnitMeasureMaster = BASE_URL + "mcrc/getUIMMaster";

//StoreMaster
export const getStoreMaster = BASE_URL + "mcrc/getStoreMaster";
export const addStoreMaster = BASE_URL + "mcrc/addStoreMaster";

//RackMaster
export const getRackMaster = BASE_URL + "mcrc/getRackMaster";
export const addRackMaster = BASE_URL + "mcrc/addRackMaster";

//Stock-InWard
export const getMasters = BASE_URL + "mcrc/centralStore/requester/getMasters";
export const openStock = BASE_URL + "mcrc/centralStore/acceptor/openStock";
export const stockInWardSubmit =
  BASE_URL + "mcrc/centralStore/acceptor/stockInWardSubmit";

//getRecieved Returns
export const getAllRecievedRequests =
  BASE_URL + "mcrc/centralStore/acceptor/getAllRecievedRequests";
export const getAllRecievedReturns =
  BASE_URL + "mcrc/centralStore/acceptor/getAllRecievedReturns";
export const submitRecievedReturns =
  BASE_URL + "mcrc/centralStore/acceptor/submitRecievedReturns";
export const productStatus =
  BASE_URL + "mcrc/centralStore/acceptor/productStatus";
export const openStockDetails =
  BASE_URL + "mcrc/centralStore/acceptor/openStockDetails";

//CorePermit

export const getAssembleLocation =
  BASE_URL + "mcrc/rmTracking/getAssembleLocations";
export const saveCorePermitForm =
  BASE_URL + "mcrc/rmTracking/saveCorePermitForm";
export const getCorePermitFormByNumber = (mobile) => {
  return `${BASE_URL}mcrc/rmTracking/getCorePermitForms?mobileNumber=${mobile}`;
};
//https://cortevamcrcuatapi.azurewebsites.net/rest/mcrc/rmTracking/getCorePermitForms?mobileNumber=9704164746
//AssetMaster
export const getAssets = BASE_URL + "mcrc/getAssets";
export const addAsset = BASE_URL + "mcrc/addAsset";

export const getMainAssets = BASE_URL + "mcrc/getMainAssets";
export const addMainAsset = BASE_URL + "mcrc/addMainAsset";
export const addProduct = BASE_URL + "mcrc/addProduct";
export const getProducts = BASE_URL + "mcrc/getProducts";
export const getCategory = BASE_URL + "mcrc/getCategoryMaster";
export const addSubAsset = BASE_URL + "mcrc/addSubAsset";
export const getSubAssets = BASE_URL + "mcrc/getSubAssets";
export const addCategoryMaster = BASE_URL + "mcrc/addCategoryMaster";
export const submitRecievedRequests =
  BASE_URL + "mcrc/centralStore/acceptor/submitRecievedRequests";
export const getroleAssetMapping = BASE_URL + "mcrc/getroleAssetMapping";
export const setroleAssetMapping = BASE_URL + "mcrc/setroleAssetMapping";
export const history = BASE_URL + "mcrc/centralStore/acceptor/history";
export const getCorePermits = BASE_URL + "mcrc/rmTracking/getCorePermits";
export const saveVendorDetails = BASE_URL + "mcrc/rmTracking/saveVendorDetails";

export const TransferCorePermit =
  BASE_URL + "mcrc/rmTracking/transferCorePermit";
//Critical Elevated WOrk

export const saveAdditionalPermit =
  BASE_URL + "mcrc/rmTracking/saveAdditionalPermit";

export const transferCorePermit =
  BASE_URL + "mcrc/rmTracking/transferCorePermit";

export const ApproveRejectPermit =
  BASE_URL + "mcrc/rmTracking/ApproveRejectPermit";
export const getAllPermitsByCorePermitID =
  BASE_URL + "mcrc/rmTracking/getAllPermitsByCorePermitID";
export const bulkUploadExcelFileFomat =
  BASE_URL + "mcrc/rmTracking/technician/bulkUploadExcelFileFomat";
export const uploadBulkWork =
  BASE_URL + "mcrc/rmTracking/technician/uploadBulkWork";
export const getUploadedExcelFileDetails =
  BASE_URL + "mcrc/rmTracking/technician/getUploadedExcelFileDetails";

export const getAllSubmittedPermits =
  BASE_URL + "mcrc/rmTracking/getAllSubmittedPermits";

export const rmComplaintsAcceptReject =
  BASE_URL + "mcrc/rmTracking/acceptor/rmComplaintsAcceptReject";

export const rmComplaints = BASE_URL + "mcrc/rmTracking/acceptor/rmComplaints";
export const rmGetmasters = BASE_URL + "mcrc/rmTracking/getMasters";

export const newRqeuestAcceptReject =
  BASE_URL + "mcrc/rmTracking/acceptor/newRqeuestAcceptReject";

export const newRqeuest = BASE_URL + "mcrc/rmTracking/acceptor/newRqeuest";

export const dailyWorkLogView =
  BASE_URL + "mcrc/rmTracking/acceptor/dailyWorkLogView";
export const dailyWorkLogViewById =
  BASE_URL + "mcrc/rmTracking/acceptor/dailyWorkLogViewById";

export const dailyWorkLogAssignedToMe =
  BASE_URL + "mcrc/rmTracking/acceptor/dailyWorkLogAssignedToMe";

export const dailyWorkLogUpdateWork =
  BASE_URL + "mcrc/rmTracking/acceptor/dailyWorkLogUpdateWork";

export const equipmentMonitorAssignedList =
  BASE_URL + "mcrc/rmTracking/acceptor/equipmentMonitorAssignedList";
export const Rmhistory = BASE_URL + "mcrc/rmTracking/acceptor/history";
export const getAllPermits = BASE_URL + "mcrc/rmTracking/getAllPermits";

export const RmhistoryGet = BASE_URL + "mcrc/farmOperations/acceptor/rmHistory";
export const FarmOperation_getMasters =
  BASE_URL + "mcrc/farmOperations/getMasters";
export const rmActivity = BASE_URL + "mcrc/farmOperations/acceptor/rmActivity ";
export const getAllRecievedRequestsFarmOperation =
  BASE_URL + "mcrc/farmOperations/acceptor/getAllRecievedRequests";
export const requestAcceptReject =
  BASE_URL + "mcrc/farmOperations/acceptor/requestAcceptReject";
export const getAllRecievedRequestsForUpdateActivity =
  BASE_URL +
  "mcrc/farmOperations/acceptor/getAllRecievedRequestsForUpdateActivity";
export const FarmOperationsgetMasters =
  BASE_URL + "mcrc/farmOperations/getMasters";
export const FarmOperationRMHistoryAll =
  BASE_URL + "mcrc/farmOperations/acceptor/getAllrmHistory";
export const FarmOperationAcceptHistory =
  BASE_URL + "mcrc/farmOperations/acceptor/acceptorHistory";
export const FarmOperationReOpenActivity =
  BASE_URL + "mcrc/farmOperations/acceptor/reOpenActivity";
export const deleteActivity =
  BASE_URL + "mcrc/farmOperations/acceptor/deleteActivity";
export const dashboardCountSummary =
  BASE_URL + "mcrc/Login/dashboardCountSummary";
//for CS Reports APIs
export const reportForStockInwardRequestReturn =
  BASE_URL + "mcrc/centralStore/acceptor/centralStoreReports";

//for R&M Reports APIs

export const reportForRMTracking =
  BASE_URL + "mcrc/rmTracking/rmTrackingReports";

/////new dashboard apis

export const turnAroundAPI =
  BASE_URL + "mcrc/centralStore/requester/getTurnAroundTime";
export const RequestDeliverdCountAPI =
  BASE_URL + "mcrc/centralStore/requester/getRequestedandDeliveredCount";
export const CostAnalysisAPI =
  BASE_URL + "mcrc/centralStore/requester/getCostCenterAnalysis";
export const MonthlyRequestedDataAPI =
  BASE_URL + "mcrc/centralStore/requester/getRequestFilledWithMonth";

// Man power

export const getEmployeeMasterDetails =
  BASE_URL + "mcrc/manPower/getEmployeeMasterDetails";
export const saveEmployeeMaster = BASE_URL + "mcrc/manPower/saveEmployeeMaster";
export const getEmployeeMasterDetailsById =
  BASE_URL + "mcrc/manPower/getEmployeeMasterDetailsById";
export const getEmployeeDetailsBasedOnName =
  BASE_URL + "mcrc/getEmployeeDetailsBasedOnName";
export const getLegalEntities = BASE_URL + "mcrc/manPower/getLegalEntities";
export const changePassword = BASE_URL + "mcrc/changePassword";
export const workPermitsHistory =
  BASE_URL + "mcrc/rmTracking/workPermitsHistory";

//Analysis DashBoard
export const getTurnAroundTime = BASE_URL + "mcrc/rmTracking/getTurnAroundTime";
export const getRequestedandDeliveredCount =
  BASE_URL + "mcrc/rmTracking/getRequestedandDeliveredCount";
export const getRequestFilledWithMonth =
  BASE_URL + "mcrc/rmTracking/getRequestFilledWithMonth";
export const getCorePermitApprovedData =
  BASE_URL + "mcrc/rmTracking/getCorePermitApprovedData";
export const farmOperationgetTurnAroundTime =
  BASE_URL + "mcrc/farmOperations/getTurnAroundTime";
export const farmOperationGetRequestFullfill =
  BASE_URL + "mcrc/farmOperations/getRequestFilledWithMonth";
export const FarmOperationgetRequestedandDeliveredCount =
  BASE_URL + "mcrc/farmOperations/getRequestedandDeliveredCount";
export const getCurrentActivityUpdate =
  BASE_URL + "mcrc/farmOperations/getCurrentActivityUpdate";
export const getMainCropFunctionBasedOnEmployeeName =
  BASE_URL + "mcrc/getMainCropFunctionBasedOnEmployeeName";
export const getSubCropFunctionBasedOnEmployeeName =
  BASE_URL + "mcrc/getSubCropFunctionBasedOnEmployeeName";
export const getEquipmentBreakdownCount =
  BASE_URL + "mcrc/rmTracking/getEquipmentBreakdownCount";

export const sendCentralGridDataToMail =
  BASE_URL + "mcrc/centralStore/acceptor/sendCentralGridDataToMail";
